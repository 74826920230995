// Search Filter Function
import i18n from 'i18next';
import moment from 'moment';
import * as types from './actions';

import axios from 'axios';
import helpers from '../App/helpers';
import axiosInterceptor from '../App/services/interceptor';

export function selectField(field) {
    return { type: types.SELECT_FIELD, field };
}

export function unselectField(field) {
    return { type: types.UNSELECT_FIELD, field };
}

export function addFilter() {
    return { type: types.ADD_FILTER };
}

export const removeFilter = (filterId, state) => (dispatch, getState) => {
    dispatch({ type: types.REMOVE_FILTER, filterId });
    dispatch(apiCall());
};

export function clearFilters() {
    return { type: types.CLEAR_FILTERS };
}

export const clearFiltersButton = (state) => (dispatch, getState) => {
    dispatch({ type: types.CLEAR_FILTERS })
    dispatch(apiCall());
};

export function changeFilterField(filterId, fieldName) {
    return { type: types.CHANGE_FILTER_FIELD, filterId, fieldName };
}

export function changeFilterOperator(filterId, operator) {
    return { type: types.CHANGE_FILTER_OPERATOR, filterId, operator };
}

export function changeFilterValue(filterId, value) {
    return { type: types.CHANGE_FILTER_VALUE, filterId, value };
}

export function updateAvailableField(value) {
    return { type: types.UPDATE_AVAILABLE_FIELD, value };
}

export const showAlertModal =
    (state, title, onHide, options) => (dispatch, getState) => {
        dispatch({ type: types.SHOW_ALERT_MODAL, title, onHide, options });
    };

export const hideAlertModal = (state) => (dispatch, getState) => {
    dispatch({ type: types.HIDE_ALERT_MODAL });
};

export const apiCall =
    (sortBy, direction) => (dispatch, getState) => {
        dispatch({ type: types.UPDATE_API_DATA, payload: '' });
        dispatch({ type: types.UPDATE_LOADING_STATUS, loading: true });
        let state = getState();
        let body = state.apiBody;
        let apiURL = state.apiEndpoint + state.apiUri;
        if (sortBy) {
            body.sortBy = sortBy;

            switch (direction) {
                case 'desc':
                    body.direction = 'asc';
                    break;
                case 'asc':
                    body.direction = 'desc';
                    break;
                default:
                    body.direction = 'asc';
                    break;
            }
        }
        if (state.filterState !== undefined) {
            state.filterState.filters.map((filter) => {
                if (filter.field.key.split('.')[1] !== undefined) {
                    body[filter.field.key.split('.')[0]] = {};
                    body[filter.field.key.split('.')[0]][
                        filter.field.key.split('.')[1]
                    ] = filter.value;
                } else {
                    body[filter.field.key.split('.')[0]] = filter.value;
                }
            });
        }

        axiosInterceptor
            .post(apiURL, body)
            .then((res) => {
                let apiResult = {};
                switch (state.apiType) {
                    default:
                        apiResult = {
                            totalRecords: res.data.totalRecords,
                            totalPageNumber: Math.ceil(
                                res.data.totalRecords / body.pageSize
                            ),
                            result: res.data.searchResultList,
                            body: body,
                        };
                        break;
                }
                dispatch({ type: types.UPDATE_API_DATA, payload: apiResult });
                dispatch({ type: types.UPDATE_LOADING_STATUS, loading: false })
            })
            .catch((error) => {
                if (error.response || error === "api_issue") {
                    if (state.history !== undefined) {
                        dispatch({
                            type: types.API_ERROR,
                            errorResponse: error === "api_issue" ? error : error.response,
                            pathname: state.history.location.pathname,
                        });
                    } else {
                        dispatch({
                            type: types.API_ERROR,
                            errorResponse: error === "api_issue" ? error : error.response,
                        });
                    }
                }

                dispatch({ type: types.UPDATE_LOADING_STATUS, loading: false })
            });
    };


export const apiCallFormData =
    (sortBy, direction) => (dispatch, getState) => {
        let formData = "";
        dispatch({ type: types.UPDATE_FORM_FIELD, formData: formData });
        dispatch({ type: types.UPDATE_LOADING_STATUS, loading: true });
        let state = getState();
        let apiURL = state.apiEndpoint + state.apiUri;

        axiosInterceptor
            .get(apiURL)
            .then((res) => {
                let formData = {};
                dispatch({ type: types.UPDATE_LOADING_STATUS, loading: false })
            })
            .catch((error) => {
                if (error.response || error === "api_issue") {
                    if (state.history !== undefined) {
                        dispatch({
                            type: types.API_ERROR,
                            errorResponse: error === "api_issue" ? error : error.response,
                            pathname: state.history.location.pathname,
                        });
                    } else {
                        dispatch({
                            type: types.API_ERROR,
                            errorResponse: error === "api_issue" ? error : error.response,
                        });
                    }
                }
                dispatch({ type: types.UPDATE_LOADING_STATUS, loading: false })
            });
    };

export const loginApiCall = () => (dispatch, getState) => {
    localStorage.setItem('apiFields', '');
    localStorage.setItem('systemParam', '');
    dispatch({ type: types.UPDATE_API_FIELDS, apiFields: '' });
    dispatch({ type: types.UPDATE_SYSTEM_PARAM, systemParam: '' });
    dispatch({ type: types.UPDATE_LOADING_STATUS, loading: true });
    let state = getState();

    const getApiFields = axiosInterceptor.get(
        state.apiEndpoint + '/company/getAllField'
    );
    const getSystemParam = axiosInterceptor.get(
        state.apiEndpoint + '/company/getSystemParam'
    );
    axios
        .all([getApiFields, getSystemParam])
        .then(
            axios.spread((...responses) => {
                const apiFields = responses[0];
                const systemParam = responses[1];
                localStorage.setItem('apiFields', JSON.stringify(apiFields.data));
                localStorage.setItem('systemParam', JSON.stringify(systemParam.data));
                dispatch({ type: types.UPDATE_API_FIELDS, apiFields: apiFields.data });
                dispatch({ type: types.UPDATE_SYSTEM_PARAM, systemParam: systemParam.data });
                dispatch({ type: types.UPDATE_LOADING_STATUS, loading: false })

                window.location.reload();
            })
        )
        .catch((error) => {
            if (error.response || error === "api_issue") {
                if (state.history !== undefined) {
                    dispatch({
                        type: types.API_ERROR,
                        errorResponse: error === "api_issue" ? error : error.response,
                        pathname: state.history.location.pathname,
                    });
                } else {
                    dispatch({
                        type: types.API_ERROR,
                        errorResponse: error === "api_issue" ? error : error.response,
                    });
                }
            }
            dispatch({ type: types.UPDATE_LOADING_STATUS, loading: false })
        });
};

export const updateAPIPageNo = (pageNo) => (dispatch, getState) => {
    dispatch({ type: types.UPDATE_API_PAGE_NUMBER, pageNo: pageNo });
    dispatch(apiCall());
};

export const updateAPIPageSize = (pageSize) => (dispatch, getState) => {
    dispatch({ type: types.UPDATE_API_PAGE_SIZE, pageSize: pageSize });
    dispatch({ type: types.UPDATE_API_PAGE_NUMBER, pageNo: 1 });
    dispatch(apiCall());
};

export const updateLoadingStatus = (loading) => (dispatch, getState) => {
    dispatch({ type: types.UPDATE_LOADING_STATUS, loading: loading });
}

export const loadPage = (apiUri, apiType) => async (dispatch, getState) => {
    dispatch({ type: types.UPDATE_API, apiUri: apiUri, apiType: apiType });
    dispatch(apiCall());
};

export const resetAPIBody = () => {
    return { type: types.RESET_API_BODY };
};

export const updateRegionCode =
    (regionCode, reload) => (dispatch, getState) => {
        dispatch({ type: types.UPDATE_REGION_CODE, regionCode: regionCode });
        if (reload) {
            dispatch(loginApiCall());
        }
    };

export const updateLanguageCode =
    (languageCode, reload) => (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_LANGUAGE_CODE,
            languageCode: languageCode,
        });
        i18n.changeLanguage(languageCode);
        if (reload === true) {
            window.location.reload();
        }
    };

export const fieldChange = (fieldKey, fieldType, value) => (dispatch, getState) => {
    let state = getState();
    let formData = { ...state.formData };

    switch (fieldType) {
        case 'gender':
        case 'autocomplete':
            formData.dropdownValue[fieldKey] = Array.isArray(value) ? value : [value];
            break;
        case 'image':
            formData.imageValue = value;
            break;
        case 'checkbox':
            let checkboxValue =
                fieldKey.split('.')[1] === undefined
                    ? (formData[fieldKey])
                    : (formData[fieldKey.split('.')[0]][fieldKey.split('.')[1]]);
            checkboxValue = checkboxValue === true ? false : true;

            fieldKey.split('.')[1] === undefined
                ? (formData[fieldKey] = checkboxValue)
                : (formData[fieldKey.split('.')[0]][fieldKey.split('.')[1]] = checkboxValue);
            break;
        case 'array':
            let item = parseInt(value);
            let array =
                fieldKey.split('.')[1] === undefined
                    ? formData[fieldKey]
                    : formData[fieldKey.split('.')[0]][fieldKey.split('.')[1]];
            let index = array.indexOf(item);

            index === -1
                ? array.push(item)
                : array.splice(index, 1);
            break;
        default:
            fieldKey.split('.')[1] === undefined
                ? (formData[fieldKey] = value)
                : (formData[fieldKey.split('.')[0]][fieldKey.split('.')[1]] = value);
            break;
    }

    if (
        (
            (fieldType === 'time' || fieldType === 'date' || fieldType === 'array' || fieldType === 'dateTime')
            || fieldKey.split('.')[1] === "isAssessment"
        )
        && formData.info
        && formData.info.isAssessment === false
    ) {
        dispatch(updateDropdown(formData, "roster", "user"));
    }

    // clear salesChannel for select all in Form Setup
    if (formData.selectAll === true) {
        formData.dropdownValue.salesChannel = [];
    }

    // add salesInput customers data for roster edit page
    if (fieldKey === "dropdown_customers") {
        if (value) {
            let chosenCustomer = formData.customers?.[formData.customers?.map(e => e.id).indexOf(value.value)];
            if (chosenCustomer) {
                formData.chosenCustomerId = chosenCustomer.id
                formData.chosenSalesInputId = chosenCustomer.salesInputId;
            } else {
                formData.chosenCustomerId = formData.dropdown.customers[formData.dropdown.customers.map(e => e.value).indexOf(value.value)].value
                formData.chosenSalesInputId = null;
            }
        } else {
            formData.chosenSalesInputId = undefined;
        }
    }

    // add salesInput event data for roster edit page
    if (fieldKey === "dropdown_events") {
        if (value) {
            let chosenEvent = formData.events?.[formData.events?.map(e => e.id).indexOf(value.value)];
            if (chosenEvent) {
                formData.chosenEventId = chosenEvent.id
                formData.chosenSalesInputId = chosenEvent.salesInputId;
            } else {
                formData.chosenEventId = formData.dropdown.events[formData.dropdown.events.map(e => e.value).indexOf(value.value)].value
                formData.chosenSalesInputId = null;
            }
        } else {
            formData.chosenSalesInputId = undefined;
        }
    }

    dispatch({
        type: types.UPDATE_FORM_FIELD,
        formData: formData,
    });
};

export const formReset = () => (dispatch, getState) => {
    dispatch({ type: types.RESET_FORM_FIELD });
    dispatch({ type: types.RESET_ERROR_LIST });
};

export const formUpdate = (formData) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE_FORM_FIELD,
        formData: formData,
    });
};
export const errorHandle = (error, location) => (dispatch, getState) => {
    if (error.status || error === "api_issue") {
        dispatch({
            type: types.API_ERROR,
            errorResponse: error,
            pathname: location ? location.pathname : false,
        });
    }
};

export const modelStatus = (showModal) => (dispatch, getState) => {
    dispatch({
        type: types.MODEL_STATUS,
        showModal: showModal,
    });
};

export const modelHandle = (modelBody) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE_MODEL,
        modelBody: modelBody,
    });
};

export const modelReset = () => (dispatch, getState) => {
    dispatch({
        type: types.RESET_MODEL,
        modelBody: "",
        showModal: false,
    });
};

export const updateDropdown = (formData, section, type) => (dispatch, getState) => {
    let state = getState();
    let apiPath = state.apiFields[section].fields[state.apiFields[section].fields.map(e => e.key).indexOf(type)].apiPath;
    let body = {};

    if (formData.startTime !== "") {
        body.startTime = moment(formData.startTime).format('HH:mm');
    }
    if (formData.endTime !== "") {
        body.endTime = moment(formData.endTime).format('HH:mm');
    }

    body.period = {};
    if (formData.period.periodStartDate !== "") {
        body.period.periodStartDate = moment(formData.period.periodStartDate)
            .startOf('day')
            .toDate();
    }
    if (formData.period.periodEndDate !== "") {
        body.period.periodEndDate = moment(formData.period.periodEndDate)
            .startOf('day')
            .toDate();
    }
    if (formData.period.weekday && formData.period.weekday.length > 0) {
        body.period.weekday = formData.period.weekday;
    }

    dispatch(updateLoadingStatus(true));
    axiosInterceptor.post(
        state.apiEndpoint + apiPath, body
    )
        .then(
            (res) => {
                let dropdown = [];
                res.data.map((value, key) => {
                    dropdown[key] = { value: value.id, label: value.value };
                });

                formData.dropdown[type] = dropdown;
                dispatch(formUpdate(formData));
                dispatch(updateLoadingStatus(false));
            }
        )
        .catch((error) => {
            dispatch(errorHandle(error, this.props.location));
            dispatch(updateLoadingStatus(false));
        });
};


export const handleSubmitCatch = (response, body, fields, location) => (dispatch, getState) => {
    let pathname = location.pathname;
    helpers.convertDateStringToDate(fields, body);
    helpers.convertArrayToTextarea(fields, body);
    dispatch({
        type: types.API_ERROR,
        errorResponse: response,
        pathname: pathname,
    });

    (
        response.data
        && response.data !== ''
    ) &&
        dispatch({
            type: types.UPDATE_ERROR_LIST,
            errorList: response.data.map((value) => {
                return value.labelId;
            }),
        });
}